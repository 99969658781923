import React from 'react';
import { PageTemplate } from '../templates/PageTemplate';

export const Home = () => {
  return (
    <PageTemplate headerText="Home">
      <p>テスト</p>
    </PageTemplate>
  );
};
